import React from 'react';
import { Link } from 'react-router-dom';
import { contact } from '../../App';
import EmailIcon from '../Icons/EmailIcon';
import PhoneIcon from '../Icons/PhoneIcon';
import FacebookLogo from '../Icons/FacebookLogo';
import InstagramLogo from '../Icons/InstagramLogo';
import TwitterLogo from '../Icons/TwitterLogo';
import Styles from './Footer.module.scss';

export default function Footer(): JSX.Element {
  return (
    <footer className="row py-3">
      <div className="col-md-6 mb-3">
        <div>
          <a
            className={`mb-2 d-inline-block ${Styles.links}`}
            href={`tel:${contact.phone}`}
          >
            <PhoneIcon id="PhoneIcon" className="me-2" />
            {contact.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}
          </a>
        </div>
        <div>
          <a
            className={`mb-2 d-inline-block ${Styles.links}`}
            href="https://www.google.com/maps/dir//Three+Bees+Pottery+And+Coffee+Shop,+925+Southwest+Blvd,+Kansas+City,+KS+66103/"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            <EmailIcon id="EmailIcon" className="me-2" />
            925 Southwest Blvd, Kansas City, KS 66103
          </a>
        </div>
      </div>
      <div className="col-md-6 text-center text-md-end">
        <a
          className={`mb-2 ms-2 ${Styles.links}`}
          href={contact.facebook}
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          <FacebookLogo id="FacebookLogo" />
        </a>
        <a
          className={`mb-2 ms-2 ${Styles.links}`}
          href={contact.instagram}
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          <InstagramLogo id="InstagramLogo" />
        </a>
        <a
          className={`mb-2 ms-2 ${Styles.links}`}
          href={contact.twitter}
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          <TwitterLogo id="TwitterLogo" />
        </a>
      </div>
      <div className="my-2">
        <Link to="privacy-policy" className={Styles.links}>
          Privacy Policy
        </Link>
      </div>
      <div className="mb-2">
        &copy; {new Date().getFullYear()} Three Bees Pottery and Coffee Shop.
        All rights reserved.
      </div>
    </footer>
  );
}
