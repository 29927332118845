import React from 'react';
import SvgIcon from './SvgIcon';

type Props = {
  className?: string;
  id: string;
  isActive?: boolean;
};

const defaultProps = {
  className: '',
  isActive: false,
};

function InstagramLogo({
  className,
  id,
  isActive,
  ...other
}: Props): JSX.Element {
  return (
    <SvgIcon
      id={id}
      className={className}
      title="Instagram Logo"
      isActive={isActive}
      viewBox="0 0 100 100"
      {...other}
    >
      <path
        d="M50,5C37.779,5,36.246,5.052,31.446,5.271c-4.79,0.219-8.061,0.979-10.923,2.092
        c-2.959,1.15-5.469,2.688-7.971,5.19c-2.502,2.501-4.041,5.011-5.19,7.97c-1.112,2.862-1.873,6.134-2.092,10.923
        C5.052,36.247,5,37.779,5,50c0,12.221,0.052,13.753,0.271,18.553c0.219,4.79,0.979,8.062,2.092,10.923
        c1.149,2.96,2.688,5.469,5.19,7.971s5.012,4.041,7.971,5.19c2.862,1.112,6.133,1.873,10.923,2.092C36.246,94.948,37.779,95,50,95
        s13.754-0.052,18.553-0.271c4.791-0.219,8.062-0.979,10.924-2.092c2.959-1.149,5.469-2.688,7.971-5.19s4.041-5.011,5.19-7.971
        c1.112-2.861,1.873-6.133,2.091-10.923C94.948,63.754,95,62.222,95,50c0-12.222-0.052-13.754-0.271-18.554
        c-0.218-4.79-0.979-8.061-2.091-10.923c-1.149-2.959-2.688-5.469-5.19-7.97c-2.502-2.502-5.012-4.041-7.971-5.19
        C76.614,6.25,73.344,5.49,68.553,5.271C63.754,5.052,62.221,5,50,5L50,5z M50,13.108c12.016,0,13.439,0.046,18.184,0.262
        c4.388,0.2,6.771,0.933,8.355,1.549c2.102,0.816,3.6,1.792,5.175,3.366s2.55,3.074,3.366,5.174c0.616,1.586,1.35,3.968,1.549,8.356
        c0.217,4.745,0.264,6.168,0.264,18.184c0,12.015-0.047,13.439-0.264,18.183c-0.199,4.389-0.933,6.771-1.549,8.356
        c-0.816,2.101-1.791,3.6-3.366,5.174c-1.575,1.575-3.073,2.55-5.175,3.366c-1.585,0.616-3.968,1.35-8.355,1.55
        c-4.744,0.217-6.167,0.263-18.184,0.263s-13.439-0.046-18.184-0.263c-4.388-0.2-6.771-0.934-8.355-1.55
        c-2.102-0.816-3.6-1.791-5.175-3.366c-1.575-1.574-2.55-3.073-3.366-5.174c-0.616-1.586-1.35-3.968-1.549-8.356
        c-0.217-4.744-0.264-6.168-0.264-18.183c0-12.016,0.047-13.439,0.264-18.184c0.199-4.388,0.933-6.77,1.549-8.356
        c0.816-2.101,1.791-3.6,3.366-5.174c1.575-1.575,3.073-2.55,5.175-3.367c1.585-0.616,3.968-1.349,8.355-1.549
        C36.561,13.154,37.984,13.108,50,13.108"
      />
      <path
        d="M50,65c-8.284,0-15-6.715-15-15s6.716-15,15-15s15,6.715,15,15S58.284,65,50,65z M50,26.892
        c-12.762,0-23.107,10.346-23.107,23.108c0,12.762,10.346,23.108,23.107,23.108S73.107,62.763,73.107,50
        C73.107,37.238,62.762,26.892,50,26.892"
      />
      <path
        d="M79.422,25.979c0,2.982-2.418,5.4-5.4,5.4s-5.4-2.417-5.4-5.4s2.418-5.4,5.4-5.4
        S79.422,22.997,79.422,25.979"
      />
    </SvgIcon>
  );
}

InstagramLogo.defaultProps = defaultProps;

export default InstagramLogo;
