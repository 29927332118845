import React from 'react';
import SvgIcon from './SvgIcon';

type Props = {
  className?: string;
  id: string;
  isActive?: boolean;
};

const defaultProps = {
  className: '',
  isActive: false,
};

function FacebookLogo({
  className,
  id,
  isActive,
  ...other
}: Props): JSX.Element {
  return (
    <SvgIcon
      id={id}
      className={className}
      title="Facebook Logo"
      isActive={isActive}
      viewBox="0 0 100 100"
      {...other}
    >
      <path
        d="M50,5C25.146,5,5,25.147,5,50c0,22.461,16.456,41.078,37.969,44.453V63.008H31.543V50h11.426v-9.914
        c0-11.278,6.719-17.508,16.997-17.508c4.923,0,10.073,0.879,10.073,0.879v11.074h-5.674c-5.591,0-7.334,3.469-7.334,7.027V50h12.48
        l-1.995,13.008H57.031v31.445C78.544,91.078,95,72.461,95,50C95,25.147,74.854,5,50,5z"
      />
    </SvgIcon>
  );
}

FacebookLogo.defaultProps = defaultProps;

export default FacebookLogo;
