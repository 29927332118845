import React, { useState } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import Styles from './Navigation.module.scss';

function Navigation(): JSX.Element {
  const navLinks = [
    {
      linkText: 'Home',
      url: '/',
    },
    {
      linkText: 'Menu',
      url: '/menu',
    },
  ];

  const [displayMobileNav, setDisplayMobileNav] = useState(false);
  const [inTransition, setInTransition] = useState(false);

  const closeMenu = (): void => {
    if (displayMobileNav) setInTransition(true);
    setDisplayMobileNav(false);
    setTimeout(() => {
      setInTransition(false);
      document.body.classList.remove('overflow-hidden');
    }, 300); // 0.3s for css animation duration
  };

  // const toggleMenu = (): void => {
  //   // eslint-disable-next-line no-unused-expressions
  //   document.body.classList.contains('overflow-hidden')
  //     ? document.body.classList.remove('overflow-hidden')
  //     : document.body.classList.add('overflow-hidden');

  //   // eslint-disable-next-line no-unused-expressions
  //   displayMobileNav ? closeMenu() : setDisplayMobileNav(true);
  // };

  return (
    <div
      className={clsx(Styles.nav_background, {
        [Styles.mobile_nav]: displayMobileNav,
        [Styles.mobile_nav__transition]: inTransition,
      })}
    >
      <div className={Styles.nav_wrapper}>
        <nav className={Styles.nav}>
          <ul>
            {navLinks.map((link) => (
              <li key={link.linkText}>
                <NavLink
                  to={link.url}
                  className={`${Styles.nav_link} ${Styles.navLink}`}
                  // activeClassName={Styles.activeLink}
                  onClick={closeMenu}
                  // exact
                >
                  <span className={Styles.nav_link__text}>{link.linkText}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className={Styles.close_background} onClick={closeMenu} />
    </div>
  );
}

export default Navigation;
