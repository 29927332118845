import React from 'react';
import SvgIcon from './SvgIcon';

type Props = {
  className?: string;
  id: string;
  isActive?: boolean;
};

const defaultProps = {
  className: '',
  isActive: false,
};

function ChevronLeftIcon({
  className,
  id,
  isActive,
  ...other
}: Props): JSX.Element {
  return (
    <SvgIcon
      id={id}
      className={className}
      title="Email"
      isActive={isActive}
      viewBox="0 0 24 24"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...other}
    >
      <polyline points="15 18 9 12 15 6" />
    </SvgIcon>
  );
}

ChevronLeftIcon.defaultProps = defaultProps;

export default ChevronLeftIcon;
