import React from 'react';
import { Helmet } from 'react-helmet-async';
import MenuJSON from '../../api/menu.json';
import Styles from './Menu.module.scss';

type MenuCategoryType = {
  id: number;
  name: string;
  description?: string | null;
  categoryItems: {
    id: number;
    name: string;
    description: string | null;
    price: number;
    dairyFree?: boolean;
    vegetarian?: boolean;
    vegan?: boolean;
    soldOut?: boolean;
  }[];
};

type TamaleCategoryType = {
  id: number;
  name: string;
  categoryItems: {
    id: number;
    name: string;
    description: string | null;
    price: number;
    priceGrouping?: {
      single: number;
      six: number;
      twelve: number;
    };
    dairyFree?: boolean;
    vegetarian?: boolean;
    vegan?: boolean;
    soldOut?: boolean;
  }[];
};

function MenuItem({
  menuCategory,
}: {
  menuCategory: MenuCategoryType;
}): JSX.Element {
  return (
    <>
      <h3 className={`text-center ${Styles.heading}`}>{menuCategory.name}</h3>
      <div className={Styles.heading_description}>
        {menuCategory.description}
      </div>
      {menuCategory.categoryItems.map((categoryItem) => (
        <div key={categoryItem.id} className={Styles.menu_item}>
          <div className={`d-flex justify-content-between align-items-end`}>
            <span className={Styles.menu_item_name}>{categoryItem.name}</span>
            <span className={Styles.menu_item_price}>
              {Number(categoryItem.price).toFixed(2)}
            </span>
          </div>
          <div className={Styles.menu_item_border}></div>
          <div className={Styles.menu_item_description}>
            {categoryItem.description}
          </div>
        </div>
      ))}
    </>
  );
}

function Tamales({
  tamaleCategory,
}: {
  tamaleCategory: TamaleCategoryType;
}): JSX.Element {
  const tamales: {
    name: string[];
    price?: { single: number; six: number; twelve: number };
  }[] = [];

  tamaleCategory.categoryItems.forEach((el) => {
    const found = tamales.find(
      (item) => item.price?.single === el.priceGrouping?.single
    );

    if (found) {
      found.name.push(el.name);
    } else {
      tamales.push({ name: [el.name], price: el.priceGrouping });
    }
  });

  return (
    <>
      <h3 className={`text-center ${Styles.heading}`}>{tamaleCategory.name}</h3>
      {tamales.map((tamale) => (
        <div key={tamale.price?.single} className={Styles.menu_item}>
          <div className={Styles.menu_item_name}>{tamale.name.join(', ')}</div>
          <div className="text-end mb-3">
            <div className="d-xl-inline">
              <span className={Styles.quantity}>Single</span>{' '}
              {Number(tamale.price?.single).toFixed(2)}
            </div>
            <div className="d-xl-inline ps-3">
              <span className={Styles.quantity}>Six</span>{' '}
              {Number(tamale.price?.six).toFixed(2)}
            </div>
            <div className="d-xl-inline ps-3">
              <span className={Styles.quantity}>Twelve</span>{' '}
              {Number(tamale.price?.twelve).toFixed(2)}
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

function Menu(): JSX.Element {
  return (
    <>
      <Helmet>
        <title>Three Bees KCK - Menu</title>
        <meta
          name="description"
          content="Three Bees Pottery and Coffee Shop Menu. In house made tamales, birria burrito, rice, beans, cookies, and brownies. Also serving horchata, atole, champurrado, and tres leches lattes."
        />
      </Helmet>
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-lg-6 col-12 mb-3">
              <Tamales tamaleCategory={MenuJSON[0]} />
            </div>
            <div className="col-lg-6 col-12 mb-3">
              <MenuItem menuCategory={MenuJSON[1]} />
              <MenuItem menuCategory={MenuJSON[2]} />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12 mb-3">
              <MenuItem menuCategory={MenuJSON[3]} />
            </div>
            <div className="col-lg-6 col-12 mb-3">
              <MenuItem menuCategory={MenuJSON[4]} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Menu;
