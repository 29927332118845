import React from 'react';
import SvgIcon from './SvgIcon';

type Props = {
  className?: string;
  id: string;
  isActive?: boolean;
};

const defaultProps = {
  className: '',
  isActive: false,
};

function ChevronRightIcon({
  className,
  id,
  isActive,
  ...other
}: Props): JSX.Element {
  return (
    <SvgIcon
      id={id}
      className={className}
      title="Email"
      isActive={isActive}
      viewBox="0 0 24 24"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...other}
    >
      <polyline points="9 18 15 12 9 6" />
    </SvgIcon>
  );
}

ChevronRightIcon.defaultProps = defaultProps;

export default ChevronRightIcon;
