import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    gtag?: (
      key: string,
      trackingId: string,
      config: { page_title?: string; page_location?: string }
    ) => void;
  }
}

export const useTracking = (
  trackingId: string | undefined = process.env.GA_MEASUREMENT_ID
): void => {
  const location = useLocation();

  useEffect(() => {
    const unlisten = (): void => {
      if (!window.gtag) return;
      if (!trackingId) {
        console.log(
          'Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.'
        );
        return;
      }

      window.gtag('config', trackingId, {
        page_location: `https://threebeeskck.com${location.pathname}`,
      });
    };

    return unlisten;
  }, [trackingId, location]);
};
