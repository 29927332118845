import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import StorefrontImage from '../../images/storefront-1000.jpg';
import FrontCounter from '../../images/front-counter.jpg';
import Talavera from '../../images/talavera.jpg';
import Tamales from '../../images/tamales.jpg';
import FoodDrinksPottery from '../../images/food-drinks-pottery.jpg';
import Styles from './Kiosk.module.scss';

function Kiosk(): JSX.Element {
  return (
    <Carousel
      interval={5000}
      autoPlay
      showThumbs={false}
      showStatus={false}
      infiniteLoop
      className={Styles.fullscreen}
    >
      <div>
        <img src={Tamales} alt="Pile of tamales" />
        <p className={Styles.textLeft}>
          Tamales Served Daily
          <br />
          Sold in Quantities of 1, 6, or 12
        </p>
      </div>
      <div>
        <img src={Talavera} alt="Talavera Pottery imported from Mexico" />
        <p className={Styles.textLeft}>Lots of Talavera pottery</p>
      </div>
      <div>
        <img
          src={StorefrontImage}
          alt="Three Bees Pottery and Coffee Shop storefront"
        />
        <p className={Styles.textRight}>
          Now Open 8am - 6pm
          <br />
          Tuesday - Saturday
        </p>
      </div>
      <div>
        <img
          src={FoodDrinksPottery}
          alt="Three Bees Pottery and Coffee Shop arial table shot of food, drinks, and pottery"
        />
        <p className={Styles.textLeft}>
          Fresh made tamales,
          <br />
          birria burritos, and horchata!
        </p>
      </div>
      <div>
        <img src={FrontCounter} alt="Front counter with Three Bees Menu" />
        <p className={Styles.textLeft}>Proudly serving Blip Coffee</p>
        <a
          className={`mt-3 btn btn-primary ${Styles.linkLeft}`}
          href="https://www.instagram.com/bliproasters/"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          @bliproasters
        </a>
      </div>
    </Carousel>
  );
}

export default Kiosk;
