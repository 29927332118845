import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import clsx from 'clsx';
import ChevronLeftIcon from '../../components/Icons/ChevronLeftIcon';
import ChevronRightIcon from '../../components/Icons/ChevronRightIcon';
import StorefrontImage from '../../images/storefront-1000.jpg';
// import FrontCounter from '../../images/front-counter.jpg';
// import Talavera from '../../images/talavera.jpg';
// import Tamales from '../../images/tamales.jpg';
import FoodDrinksPottery from '../../images/food-drinks-pottery.jpg';
import Styles from './Home.module.scss';

function Home(): JSX.Element {
  return (
    <Carousel
      interval={5000}
      autoPlay
      showThumbs={false}
      showStatus={false}
      infiniteLoop
      showArrows
      renderArrowPrev={(onClickHandler) => (
        <button
          onClick={onClickHandler}
          className={clsx(
            Styles.imageGallery_Button,
            Styles.imageGallery_Button_Left
          )}
        >
          <ChevronLeftIcon
            id="ChevronLeftIcon"
            className={Styles.imageGallery_LeftNav}
          />
        </button>
      )}
      renderArrowNext={(onClickHandler) => (
        <button
          onClick={onClickHandler}
          className={clsx(
            Styles.imageGallery_Button,
            Styles.imageGallery_Button_Right
          )}
        >
          <ChevronRightIcon
            id="ChevronRightIcon"
            className={Styles.imageGallery_RightNav}
          />
        </button>
      )}
    >
      <div>
        <img
          src={FoodDrinksPottery}
          alt="Three Bees Pottery and Coffee Shop arial table shot of food, drinks, and pottery"
        />
        <p className={Styles.textLeft}>
          Fresh made tamales,
          <br />
          birria burritos, and horchata!
        </p>
      </div>
      <div>
        <img
          src={StorefrontImage}
          alt="Three Bees Pottery and Coffee Shop storefront"
        />
        <p className={Styles.textRight}>
          Tuesday thru Saturday - 8am to 6pm
          <br />
          Sunday - 8am to 2pm
        </p>
      </div>
      {/* <div>
        <img src={FrontCounter} alt="Front counter with Three Bees Menu" />
        <p className={Styles.textLeft}>Proudly serving Blip Coffee</p>
        <a
          className={`mt-3 btn btn-primary ${Styles.linkLeft}`}
          href="https://www.instagram.com/bliproasters/"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          @bliproasters
        </a>
      </div> */}
    </Carousel>
  );
}

export default Home;
