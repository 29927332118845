import React from 'react';
import SvgIcon from './SvgIcon';

type Props = {
  className?: string;
  id: string;
  isActive?: boolean;
};

const defaultProps = {
  className: '',
  isActive: false,
};

function TwitterLogo({
  className,
  id,
  isActive,
  ...other
}: Props): JSX.Element {
  return (
    <SvgIcon
      id={id}
      className={className}
      title="Twitter Logo"
      isActive={isActive}
      viewBox="0 0 100 100"
      {...other}
    >
      <path
        d="M33.305,86.572c33.963,0,52.536-28.139,52.536-52.537c0-0.8-0.016-1.596-0.053-2.388
        c3.605-2.605,6.739-5.857,9.212-9.56c-3.309,1.469-6.869,2.46-10.604,2.906c3.812-2.285,6.739-5.902,8.119-10.214
        c-3.568,2.115-7.519,3.653-11.725,4.481c-3.369-3.589-8.168-5.834-13.479-5.834c-10.198,0-18.468,8.27-18.468,18.464
        c0,1.449,0.162,2.858,0.479,4.21C33.979,35.33,20.37,27.982,11.264,16.81c-1.587,2.729-2.5,5.899-2.5,9.281
        c0,6.405,3.26,12.061,8.217,15.37c-3.029-0.094-5.875-0.926-8.363-2.311c-0.004,0.077-0.004,0.154-0.004,0.235
        c0,8.943,6.365,16.409,14.813,18.103c-1.55,0.422-3.183,0.649-4.867,0.649c-1.189,0-2.347-0.117-3.472-0.333
        c2.352,7.336,9.167,12.675,17.25,12.825c-6.32,4.952-14.281,7.904-22.934,7.904c-1.489,0-2.959-0.086-4.404-0.256
        C13.172,83.516,22.875,86.572,33.305,86.572"
      />
    </SvgIcon>
  );
}

TwitterLogo.defaultProps = defaultProps;

export default TwitterLogo;
